// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/channel/utils.ts"
);
import.meta.hot.lastModified = "1731679026356.6426";
}
// REMIX HMR END

import { ChannelFragmentFragment, ListChannelFragment } from '~admin/generated/graphql'
import { ChannelToken } from './types'
import { PartialWithRequired } from '~common/types'

export const createChannelToken = (
  channel: PartialWithRequired<ListChannelFragment | ChannelFragmentFragment, 'code' | 'token'>,
): ChannelToken => {
  return {
    name: channel.code,
    token: channel.token,
    messagingClientToken: channel.customFields?.gotifyClientToken,
    avatar: channel.customFields?.storeIcon?.preview,
  }
}
